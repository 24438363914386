import { Playlist } from "@/types/playlist.type";

export const Albums: Record<string, Omit<Playlist, "tracks">> = {
  "eous-anthems": {
    playlist_name: "Eous Anthems",
    playlist_cover: "/covers/eous-anthems.png",
    playlist_id: "eous-anthems",
    playlist_type: "jukebox",
  },
  "1.4": {
    playlist_name: "A Storm of Falling Stars",
    playlist_cover: "/covers/1.4.jpg",
    playlist_id: "1.4",
    playlist_type: "jukebox",
  },
  "come-alive": {
    playlist_name: "Come Alive",
    playlist_cover: "/covers/come-alive.jpg",
    playlist_id: "come-alive",
    playlist_type: "jukebox",
  },
  "casual-savior": {
    playlist_name: "Casual Savior",
    playlist_cover: "/covers/casual-savior.png",
    playlist_id: "casual-savior",
    playlist_type: "jukebox",
  },
  "daily-loop": {
    playlist_name: "Daily Loop",
    playlist_cover: "/covers/daily-loop.png",
    playlist_id: "daily-loop",
    playlist_type: "jukebox",
  },
  "shared-earbuds": {
    playlist_name: "Shared Earbuds",
    playlist_cover: "/covers/shared-earbuds.png",
    playlist_id: "shared-earbuds",
    playlist_type: "jukebox",
  },
  "random-play": {
    playlist_name: "Random Play",
    playlist_cover: "/covers/random-play.png",
    playlist_id: "random-play",
    playlist_type: "jukebox",
  },
  "virtual-revenge": {
    playlist_name: "Virtual Revenge",
    playlist_cover: "/covers/virtual-revenge.jpeg",
    playlist_id: "virtual-revenge",
    playlist_type: "jukebox",
  },
  ellen: {
    playlist_name: "Ellen Joe",
    playlist_cover: "/characters/ellen.png",
    playlist_id: "ellen-joe",
    playlist_type: "character",
  },
  burnice: {
    playlist_name: "Burnice",
    playlist_cover: "/characters/burnice.png",
    playlist_id: "burnice",
    playlist_type: "character",
  },
  anby: {
    playlist_name: "Anby",
    playlist_cover: "/characters/anby.png",
    playlist_id: "anby",
    playlist_type: "character",
  },
  caesar: {
    playlist_name: "Caesar",
    playlist_cover: "/characters/caesar.png",
    playlist_id: "caesar",
    playlist_type: "character",
  },
  "zhu-yuan": {
    playlist_name: "Zhu Yuan",
    playlist_cover: "/characters/zhu-yuan.png",
    playlist_id: "zhu-yuan",
    playlist_type: "character",
  },
  jane: {
    playlist_name: "Jane",
    playlist_cover: "/characters/jane.png",
    playlist_id: "jane-doe",
    playlist_type: "character",
  },
  koleda: {
    playlist_name: "Koleda",
    playlist_cover: "/characters/koleda.png",
    playlist_id: "koleda",
    playlist_type: "character",
  },
  lighter: {
    playlist_name: "Lighter",
    playlist_cover: "/characters/lighter.png",
    playlist_id: "lighter",
    playlist_type: "character",
  },
  nicole: {
    playlist_name: "Nicole",
    playlist_cover: "/characters/nicole.png",
    playlist_id: "nicole",
    playlist_type: "character",
  },
  harumasa: {
    playlist_name: "Harumasa",
    playlist_cover: "/characters/harumasa.png",
    playlist_id: "harumasa",
    playlist_type: "character",
  },
  miyabi: {
    playlist_name: "Miyabi",
    playlist_cover: "/characters/miyabi.png",
    playlist_id: "miyabi",
    playlist_type: "character",
  },
  lycaon: {
    playlist_name: "Lycaon",
    playlist_cover: "/characters/lycaon.png",
    playlist_id: "lycaon",
    playlist_type: "character",
  },
  nekomata: {
    playlist_name: "Nekomata",
    playlist_cover: "/characters/nekomiya.png",
    playlist_id: "nekomata",
    playlist_type: "character",
  },
  qingyi: {
    playlist_name: "Qingyi",
    playlist_cover: "/characters/qingyi.png",
    playlist_id: "qingyi",
    playlist_type: "character",
  },
  yanagi: {
    playlist_name: "Yanagi",
    playlist_cover: "/characters/yanagi.png",
    playlist_id: "yanagi",
    playlist_type: "character",
  },
  loading: {
    playlist_name: "Loading",
    playlist_cover: "/covers/loading.jpg",
    playlist_id: "loading",
    playlist_type: "jukebox",
  },
  "roaming-the-ether": {
    playlist_name: "Roaming the Ether",
    playlist_cover: "/covers/roaming-the-ether.jpg",
    playlist_id: "roaming-the-ether",
    playlist_type: "jukebox",
  },
  "overlords-feast": {
    playlist_name: "Overlords Feast",
    playlist_cover: "/covers/overlords-feast.jpg",
    playlist_id: "overlords-feast",
    playlist_type: "jukebox",
  },
  "first-class-customer-service": {
    playlist_name: "First Class Customer Service",
    playlist_cover: "/covers/first-class-customer-service.jpg",
    playlist_id: "first-class-customer-service",
    playlist_type: "jukebox",
  },
  "daily-life-of-a-pubsec-officer": {
    playlist_name: "Daily Life of a PubSec Officer",
    playlist_cover: "/covers/daily-life-of-a-pubsec-officer.jpg",
    playlist_id: "daily-life-of-a-pubsec-officer",
    playlist_type: "jukebox",
  },
  "camelia-golden-week": {
    playlist_name: "Camelina Golden Week",
    playlist_cover: "/covers/camelia-golden-week.jpg",
    playlist_id: "camelia-golden-week",
    playlist_type: "jukebox",
  },
  "en-nah-assistant-program": {
    playlist_name: "En Nah' Assistant Program",
    playlist_cover: "/covers/en-nah-assistant-program.jpg",
    playlist_id: "en-nah-assistant-program",
    playlist_type: "jukebox",
  },
  "when-sandwiches-come-a-knockin": {
    playlist_name: "When Sandwiches Come a Knockin'",
    playlist_cover: "/covers/when-sandwiches-come-a-knockin.jpg",
    playlist_id: "when-sandwiches-come-a-knockin",
    playlist_type: "jukebox",
  },
  "bangboo-vs-ethereal": {
    playlist_name: "Bangboo vs Ethereal",
    playlist_cover: "/covers/bangboo-vs-ethereal.jpg",
    playlist_id: "bangboo-vs-ethereal",
    playlist_type: "jukebox",
  },
  "unreleased-songs": {
    playlist_name: "Unreleased Songs",
    playlist_cover: "/covers/unreleased-songs.jpg",
    playlist_id: "unreleased-songs",
    playlist_type: "jukebox",
  },
  billy: {
    playlist_name: "Billy",
    playlist_cover: "/characters/billy.png",
    playlist_id: "billy",
    playlist_type: "character",
  },
  grace: {
    playlist_name: "Grace",
    playlist_cover: "/characters/grace.png",
    playlist_id: "grace",
    playlist_type: "character",
  },
};
