import { Track } from "@/types/track.type";
import { Albums } from "../albums";
import { Artists } from "../artists";

export const Album14: Track[] = [
  {
    title: "ZZZ Official Web Theme",
    title_id: "zzz-official-web-theme",
    source: "/musics/1.4-zzz-official-web-theme.mp3",
    duration: 60 * 2 + 3,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Harumasa Story - Battle Theme 1",
    title_id: "harumasa-story--battle-theme-1",
    source: "/musics/1.4-harumasa-story--battle-theme-1.mp3",
    duration: 3 * 60 + 57,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Harumasa Story - Battle Theme 2",
    title_id: "harumasa-story--battle-theme-2",
    source: "/musics/1.4-harumasa-story--battle-theme-2.mp3",
    duration: 3 * 60 + 55,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Harumasa Story - Battle Theme 3",
    title_id: "harumasa-story--battle-theme-3",
    source: "/musics/1.4-harumasa-story--battle-theme-3.mp3",
    duration: 3 * 60 + 9,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Hollow Zero - Lost Void",
    title_id: "hollow-zero--lost-void",
    source: "/musics/1.4-hollow-zero--lost-void.mp3",
    duration: 2 * 60 + 36,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Main Story 1",
    title_id: "main-story-1",
    source: "/musics/1.4-main-story-1.mp3",
    duration: 2 * 60 + 58,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Main Story 2",
    title_id: "main-story-2",
    source: "/musics/1.4-main-story-2.mp3",
    duration: 3 * 60 + 25,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Main Story 3",
    title_id: "main-story-3",
    source: "/musics/1.4-main-story-3.mp3",
    duration: 2 * 60 + 35,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Main Story 4",
    title_id: "main-story-4",
    source: "/musics/1.4-main-story-4.mp3",
    duration: 3 * 60 + 23,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Main Story 5",
    title_id: "main-story-5",
    source: "/musics/1.4-main-story-5.mp3",
    duration: 60 + 50,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Main Story 6",
    title_id: "main-story-6",
    source: "/musics/1.4-main-story-6.mp3",
    duration: 2 * 60 + 29,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Main Story 7",
    title_id: "main-story-7",
    source: "/musics/1.4-main-story-7.mp3",
    duration: 60 + 48,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Main Story 8",
    title_id: "main-story-8",
    source: "/musics/1.4-main-story-8.mp3",
    duration: 3 * 60 + 3,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Main Story 9",
    title_id: "main-story-9",
    source: "/musics/1.4-main-story-9.mp3",
    duration: 60 + 59,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Main Story 10",
    title_id: "main-story-10",
    source: "/musics/1.4-main-story-10.mp3",
    duration: 43,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Port Elpis Theme",
    title_id: "port-elpis-theme",
    source: "/musics/1.4-port-elpis-theme.mp3",
    duration: 5 * 60 + 56,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Reverb Arena Theme",
    title_id: "reverb-arena-theme",
    source: "/musics/1.4-reverb-arena-theme.mp3",
    duration: 6 * 60 + 15,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Sacrifice Bringer Boss - Battle Theme 1",
    title_id: "sacrifice-bringer-boss--battle-theme-1",
    source: "/musics/1.4-sacrifice-bringer-boss--battle-theme-1.mp3",
    duration: 3 * 60 + 5,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
  {
    title: "Sacrifice Bringer Boss - Battle Theme 2",
    title_id: "sacrifice-bringer-boss--battle-theme-2",
    source: "/musics/1.4-sacrifice-bringer-boss--battle-theme-2.mp3",
    duration: 4 * 60 + 43,
    created_at: new Date("2024-12-22"),
    ...Artists["1.4"],
    ...Albums["1.4"],
  },
];
