import { FirebaseLikesRepository } from "@/repositories/FirebaseLikesRepository";
import { IModule } from "../interfaces/IModule";
import { FirebaseAuthRepository } from "@/repositories/FirebaseAuthRepository";
import { FirebasePlaylistsRepository } from "@/repositories/FirebasePlaylistsRepository";
import { Firebase } from "@/services/firebase";
import { PlausibleAnalyticsService } from "@/services/plausible-analytics-service";
import { WindowLocalstorageService } from "@/services/window-localstorage-service";

export class TestModule implements IModule {
  build() {
    const firebase = new Firebase();
    const AuthRepository = new FirebaseAuthRepository(firebase);
    const PlaylistsRepository = new FirebasePlaylistsRepository(firebase);
    const AnalyticsService = new PlausibleAnalyticsService();
    const LocalstorageService = new WindowLocalstorageService();
    const LikesRepository = new FirebaseLikesRepository(firebase);

    return {
      LocalstorageService,
      AuthRepository,
      PlaylistsRepository,
      AnalyticsService,
      LikesRepository,
    };
  }
}
