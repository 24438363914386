import { Track } from "@/types/track.type";
import { Albums } from "../albums";
import { Artists } from "../artists";

export const UnreleasedSongs: Track[] = [
  {
    title: "Agent Mix - Anby Demara",
    title_id: "agent-mix-anby-demara",
    source: "/musics/agent-mix--amby-demara.mp3",
    duration: 60 * 1 + 26,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
  {
    title: "Agent Mix - Billy",
    title_id: "agent-mix-billy",
    source: "/musics/agent-mix--billy.mp3",
    duration: 60 * 1 + 14,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
  {
    title: "Agent Mix - Caesar",
    title_id: "agent-mix-caesar",
    source: "/musics/agent-mix--caesar.mp3",
    duration: 60 * 2 + 10,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
  {
    title: "Agent Mix - Ellen",
    title_id: "agent-mix-ellen",
    source: "/musics/agent-mix--ellen.mp3",
    duration: 2 * 60 + 31,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
  {
    title: "Agent Mix - Grace",
    title_id: "agent-mix-grace",
    source: "/musics/agent-mix--grace.mp3",
    duration: 1 * 60 + 27,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
  {
    title: "Agent Mix - Jane",
    title_id: "agent-mix-jane",
    source: "/musics/agent-mix--jane.mp3",
    duration: 60 * 1 + 54,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
  {
    title: "Agent Mix - Koleda",
    title_id: "agent-mix-koleda",
    source: "/musics/agent-mix--koleda.mp3",
    duration: 60 + 22,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
  {
    title: "Agent Mix - Lighter",
    title_id: "agent-mix-lighter",
    source: "/musics/agent-mix--lighter.mp3",
    duration: 60 + 26,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
  {
    title: "Agent Mix - Nekomata",
    title_id: "agent-mix-nekomata",
    source: "/musics/agent-mix--nekomata.mp3",
    duration: 60 + 58,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
  {
    title: "Agent Mix - Nicole",
    title_id: "agent-mix-nicole",
    source: "/musics/agent-mix--nicole.mp3",
    duration: 60 + 6,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
  {
    title: "Agent Mix - Qingyi",
    title_id: "agent-mix-qingyi",
    source: "/musics/agent-mix--qingyi.mp3",
    duration: 60 + 44,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
  {
    title: "Agent Mix - Rina",
    title_id: "agent-mix-rina",
    source: "/musics/agent-mix--rina.mp3",
    duration: 60 + 8,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
  {
    title: "Agent Mix - Soldier 11",
    title_id: "agent-mix-soldier-11",
    source: "/musics/agent-mix--soldier-11.mp3",
    duration: 60 + 25,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
  {
    title: "Agent Mix - Yanagi",
    title_id: "agent-mix-yanagi",
    source: "/musics/agent-mix--yanagi.mp3",
    duration: 60 + 41,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
  {
    title: "Agent Mix - Zhu Yuan",
    title_id: "agent-mix-zhu-yuan",
    source: "/musics/agent-mix--zhu-yuan.mp3",
    duration: 60 * 2 + 12,
    created_at: new Date("2025-01-06"),
    ...Artists["san-z"],
    ...Albums["unreleased-songs"],
  },
];
