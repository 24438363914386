export const Artists = {
  bangblues: {
    artist: "Bangblues",
    artist_id: "bangblues",
  },
  catbang: {
    artist: "Catbang",
    artist_id: "catbang",
  },
  ["san-z"]: {
    artist: "San Z",
    artist_id: "san-z",
  },
  ["1.4"]: {
    artist: "1.4",
    artist_id: "1.4",
  },
};
